<template>
  <div>
    <div class="flex items-center">
      <span
        class="text-xxs lg:text-xs leading-5 text-center font-medium tracking-wider px-4"
        >{{ blok.title }}</span
      >
      <button
        type="button"
        @click="promoInfoModal = true"
        aria-label="promomodal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 icon icon-tabler icon-tabler-info-circle cursor-pointer"
          :style="`color: ${svgColor || '#ffffff'}`"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
          <path d="M12 9h.01" />
          <path d="M11 12h1v4h1" />
        </svg>
      </button>
    </div>
    <ClientOnly>
      <ModalPromoInfo
        :visibility="promoInfoModal"
        :promo="{
          subtitle: blok.info,
        }"
        @close="promoInfoModal = false"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
defineProps({
  blok: Object,
  svgColor: String,
})

const promoInfoModal = ref(false)
</script>
